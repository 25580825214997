import _ from "lodash";
import { fusionInstanceTypes } from "../../services";
import { FusionPromotionFile, FusionPromotionFileTypes, FusionPromotionResponse, FusionPromotionStates  } from "./interfaces";

const createPromotionMock = (index: number): FusionPromotionResponse => {
  return  {
    "id": `d725246b-86f2-4ab3-9f43-48d04bd8c8a${index + 1}`,
    "state": Object.keys(FusionPromotionStates)[index],
    "sourceIntegratedSystem": {
      "id": "d725246b-86f2-4ab3-9f43-48d04bd8c8b1",
      "name": "Source Integrated System",
      "type": _.sample([fusionInstanceTypes.MANAGED_FUSION, fusionInstanceTypes.SELF_HOSTED_FUSION]),
    },
    "targetIntegratedSystem": {
      "id": "d725246b-86f2-4ab3-9f43-48d04bd8c8b2",
      "name": "Target Integrated System",
      "type": _.sample([fusionInstanceTypes.MANAGED_FUSION, fusionInstanceTypes.SELF_HOSTED_FUSION]),
    },
    "prUrl": "https://github.com/lucidworks/one-platform-deployment/pull/1486",
    "selectedFiles": [
      {
        "name": "file1.txt",
        "path": "folder/file1.txt",
        "type": FusionPromotionFileTypes.FILE,
        "downloadUrl": "https://api.github.com/repos/my-org/my-repo/contents/folder/file1.txt"
      },
      {
        "name": "aaa.json",
        "path": "folder/aaa.json",
        "type": FusionPromotionFileTypes.FILE,
        "downloadUrl": "https://api.github.com/repos/my-org/my-repo/contents/folder/aaa.json"
      }
    ],
    "createdBy": "user1234",
    "creationDate": "2024-10-15 09:18:11.341098000",
    "approvedBy": "user5654",
    "approvedDate": "2024-10-16 09:18:11.341098000",
  };
};


export const fusionConfigurationPromotionMock = ():FusionPromotionResponse[] => _.times(7, (index) => createPromotionMock(index));

export const mockSimpleFiles: FusionPromotionFile[] = [
  {
    "name": "query_pipelines",
    "path": "query_pipelines",
    "downloadUrl": null,
    "type": FusionPromotionFileTypes.DIRECTORY,
  },
  {
    "name": "pipeline1.txt",
    "path": "query_pipelines/pipeline1.txt",
    "downloadUrl": "https://api.github.com/repos/owner/repo/contents/query_pipelines/branch-file1.txt",
    "type": FusionPromotionFileTypes.FILE,
  },
  {
    "name": "branch-file2.json",
    "path": "query_pipelines/branch-file2.json",
    "downloadUrl": "https://api.github.com/repos/owner/repo/contents/query_pipelines/branch-file2.json",
    "type": FusionPromotionFileTypes.FILE,
  }
];

export const mockSourceInstanceFiles: FusionPromotionFile[] =   [
  {
    "name": "query_pipelines",
    "path": "query_pipelines",
    "downloadUrl": null,
    "type": FusionPromotionFileTypes.DIRECTORY,
  },
  {
    "name": "mypipeline-others",
    "path": "query_pipelines/mypipeline-others",
    "downloadUrl": null,
    "type": FusionPromotionFileTypes.DIRECTORY,
  },
  {
     "name": "mypipeline-others.json",
     "path": "query_pipelines/mypipeline-others/mypipeline-others.json",
     "downloadUrl": "https://api.github.com/repos/owner/repo/contents/query_pipelines/mypipeline-others.json",
     "type": FusionPromotionFileTypes.FILE,
  },
  {
     "name": "mypipeline-others2.json",
     "path": "query_pipelines/mypipeline-others/mypipeline-others2.json",
     "downloadUrl": "https://api.github.com/repos/owner/repo/contents/query_pipelines/mypipeline-others2.json",
     "type": FusionPromotionFileTypes.FILE,
  },
  {
    "name": "pipeline1.json",
    "path": "query_pipelines/pipeline1.json",
    "downloadUrl": "https://api.github.com/repos/owner/repo/contents/query_pipelines/pipeline1.json",
    "type": FusionPromotionFileTypes.FILE,
  },
  {
     "name": "my_pipeline_main.json",
     "path": "query_pipelines/my_pipeline_main.json",
     "downloadUrl": "https://api.github.com/repos/owner/repo/contents/query_pipelines/my_pipeline_main.json",
     "type": FusionPromotionFileTypes.FILE,
   },
   {
     "name": "my_pipeline_secondary.json",
     "path": "query_pipelines/my_pipeline_secondary.json",
     "downloadUrl": "https://api.github.com/repos/owner/repo/contents/query_pipelines/my_pipeline_secondary.json",
     "type": FusionPromotionFileTypes.FILE,
   },
   {
     "name": "index_pipelines",
     "path": "index_pipelines",
     "downloadUrl": null,
     "type":FusionPromotionFileTypes.DIRECTORY,
   },
   {
     "name": "pipeline1.json",
     "path": "index_pipelines/pipeline1.json",
     "downloadUrl": "https://api.github.com/repos/owner/repo/contents/index_pipelines/pipeline1-others.json",
     "type": FusionPromotionFileTypes.FILE,
   },
];


export const mockSystemFiles = {
  "fbc52d23-5ff5-4f5b-a3e9-57b440247a93": mockSourceInstanceFiles,
  "others": mockSimpleFiles
}